<template>
    <div>
        <div class="text-center">
            <h6 class="text-dark text-darken-4 fw-bold-500 size14 mb-1">
                Total Transaksi
            </h6>
            <h5 class="size24 text-dark fw-bold-800 mb-1">
                {{ parseFloat($route.query.total) + totalAddtionalCost | formatAmount }}
            </h5>
            <h6 v-if="$route.query.bonus > 0" class="text-dark text-darken-4 size12 fw-bold-800">
                Termasuk potongan diskon {{ parseFloat($route.query.bonus) | formatAmount }}
            </h6>
        </div>
    </div>
</template>

<script>
import {
    preformatFloat, formatRupiah
} from '@/auth/utils'

export default {
    setup() {
        return {
            preformatFloat,
            formatRupiah
        }
    },
    data() {
        return {
            totalAddtionalCost: 0
        }
    },
    mounted() {
        this.totalAddtionalCost = 0
        const additional_costs = JSON.parse(localStorage.getItem('additional_cost'))

        for (let index = 0; index < additional_costs.length; index++) {
          const element = additional_costs[index];
          this.totalAddtionalCost += parseFloat(preformatFloat(element.additional_fee_amount))
        }
    },

}
</script>