<template>
    <div class="form-payment bg-white" id="payment--success"
        :style="$route.name == 'pos.index' ? `height: calc(100vh - 76.61px);` : ''">
        <header
            class="border border-light-bottom border-light-line border-light-top border-light-line p-2 header-payment">
            <b-row>
                <b-col cols="12">
                    <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
                        Pembayaran Berhasil
                    </h1>
                </b-col>
                <b-col cols="3" />
            </b-row>
        </header>
        <hr class="my-0">
        <b-container class="mt-2">
            <b-row class="justify-content-center">
                <b-col cols="12" md="12" lg="12" class="text-center">
                    <h5 class="text-dark fw-bold-700 mb-2 size24" style="line-height: 40px;">
                        Telah diterima dan berhasil untuk pembayaran {{ summaryTotal() | formatAmount }}
                    </h5>
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col cols="10" md="6" lg="6" class="text-center">
                    <div class="bg-light bg-background-2 py-2 border-12 size16 text-dark fw-bold-800 mb-3">
                        Kembalian pembayaran {{ parseFloat(payment_status.cash_change) | formatAmount }}
                    </div>
                </b-col>
            </b-row>
            <b-row class="justify-content-center mb-5">
                <b-col cols="5" class="text-center">
                    <b-img width="196" :src="require('@/assets/images/illustration_success.png')" />
                </b-col>
            </b-row>
            <b-row class="justify-content-center">
                <b-col cols="12" md="8" lg="" class="text-center">
                    <div class="d-flex justify-content-between">
                        <b-button class="bg-white btn-block mr-2 py-2 border-8" @click="goToPos">
                            Buat Transaksi Baru
                        </b-button>
                        <b-button class="mt-0 py-2 border-8 btn-block" @click="goToInvoice">
                            Cetak Struk
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {
    BContainer, BRow, BCol, VBToggle, BButton, BImg,
} from 'bootstrap-vue'
import { preformatFloat } from '@/auth/utils'

export default {
    title() {
        return 'Pembayaran Berhasil'
    },
    components: {
        BContainer,
        BRow,
        BCol,
        BButton,
        BImg,
    },
    setup() {
        return {
            preformatFloat
        }
    },
    directives: {
        'b-toggle': VBToggle,
    },
    data() {
        const payment_status = JSON.parse(localStorage.getItem('payment_status'))
        return {
            payment_status,
        }
    },
    mounted() {
        localStorage.removeItem('payloadDelivery')
    },
    destroyed() {
        localStorage.removeItem('payment_status')
    },
    methods: {
        summaryTotal() {
            if (this.payment_status.type_payment == 0) {
                return parseFloat(preformatFloat(this.payment_status.amount)) || 0
            }
            return parseFloat(this.payment_status.total)
        },
        goToPos() {
            this.$router.push({ name: 'pos.index' })
            this.$bvModal.hide('modal-payment-pos')
            // window.location.href = '/'
        },
        goToInvoice() {
            this.$router.push({ name: 'invoice.index', params: { id: this.$route.query.invoice_id }, query: { is_finish_transaction: this.$route.query.is_finish_transaction } })
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

button.bg-white {
    background-color: #FFFFFF !important;
    color: $dark !important;
    border: 1px solid $light--3 !important;
    font-weight: 800;
}
</style>

<style lang="scss" scoped>
body {
    background: #FFFFFF;
}

.form-payment {
    height: 100vh;

    .custom__form--input mb-0 {
        label {
            margin-bottom: 1rem !important;
        }
    }

    .image-box {
        width: 46px;
        height: 46px;
    }

    .card {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
    }
}
</style>
