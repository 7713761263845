<template>
    <b-modal id="modal-payment-pos" hide-header hide-footer ok-only ok-title="Accept" centered size="lg"
        title="Large Modal" class="custom__modal--large modal--fullscreen" body-class="p-0">
        <!-- <keep-alive>
            <components :is="currentItem.component" />
        </keep-alive> -->
        <ModalHeader v-if="$route.query.s == 1" :left-text="''" :middle-text="'Silahkan Lengkapi Data'"
            :left-function="directPage" :is-rigt-column="true" :right-function="createTransaction"
            :right-text="'Proses'" :customClassHeader="'header-payment'" />

        <div style="height: calc(100vh - 178.61px); overflow-y: auto;" v-if="$route.query.s == 1">
            <CompleteInformation ref="completeInformation" />
            <PaymentMethod ref="paymentMethod" :getActiveCart="getActiveCart" />
        </div>
        <div v-if="$route.query.s == 2">
            <PaymentStatus />
        </div>

    </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
    BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BCard,
} from 'bootstrap-vue'
import UploadPhoto from '@/components/UploadPhoto.vue'
import CompleteInformation from '@/components/Pos2/Modal/Form/CompleteInformation.vue'
import PaymentMethod from '@/components/Pos2/Modal/Form/PaymentMethod.vue'
import PaymentStatus from '@/components/Pos2/Modal/Form/PaymentStatus.vue'
import { ref, watch } from '@vue/composition-api'

import ModalHeader from "@/components/ModalHeader.vue";

export default {
    components: {
        BModal,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BContainer,
        BCard,
        UploadPhoto,
        CompleteInformation,
        PaymentMethod,
        PaymentStatus,
        ModalHeader
    },
    props: {
        formData: {
            // eslint-disable-next-line vue/require-prop-type-constructor
            type: '',
        },
        createItem: {
            type: Function,
        },
        editItem: {
            type: Function,
        },
        editId: {
            type: String,
        },
        updateItem: {
            type: Function,
        },
        cleanUpForm: {
            type: Function,
        },
        iconBank: {
            type: String,
        },
        getActiveCart: {
            type: Function,
        },
    },
    watch: {
        iconBank(value) {
            this.photo_url = value
        },
        editId(value) {
            this.editUuid = value
        },
        formData(value) {
            this.formPayload = value
        },
        formPayload: {
            handler(value) {
                this.$emit('getPayload', value)
            },
            deep: true,
        },
        // '$route.query.s'(stepId) {
        //     const step = this.steps.filter(s => s.id == stepId)
        //     this.currentItem = step[0]
        // }
    },
    setup() {
        const steps = ref([
            {
                id: 1,
                name: 'complete_information',
                label: 'Complete Information',
                component: 'CompleteInformation',
            },
            {
                id: 2,
                name: 'payment_method',
                label: 'Payment Method',
                component: 'PaymentMethod',
            },
            {
                id: 3,
                name: 'payment_status',
                label: 'Payment Status',
                component: 'PaymentStatus',
            },
        ])

        const currentItem = ref(steps.value[0])

        return {
            currentItem,
            steps,
        }
    },
    data() {
        return {
            formPayload: {
                name: '',
                description: '',
                logo: '',
            },
            photo_url: '',
            editUuid: null,
        }
    },
    methods: {
        async createTransaction() {
            try {
                await this.$refs.completeInformation.collectData()
                await this.$refs.paymentMethod.collectData()
                await this.$refs.paymentMethod.createTransaction()
            } catch (error) {
                console.log(error);
            }
        },
        getPhoto(value) {
            this.formPayload.logo = value.photo
        },
        directPage() {
            this.$router.replace({ query: { uuid: this.$route.query.uuid } });
            this.$bvModal.hide('modal-payment-pos')
        },
    },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #EFF1F5;
    border: 1px dashed #7A7F94;
    border-radius: 8px;
    position: relative;

    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;

    width: 140px;
    height: 140px;

    .floating__close--button {
        position: absolute;
        top: 6px;
        width: 24px;
        height: 24px;
        right: 6px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
